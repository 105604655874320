import React from "react";

import guaranteeFlower from "@/public/assets/icons/gurantee-logo.svg";

import style from "./guarentee-banner.module.scss";
import Image from "next/image";
const GuaranteeBanner = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.bg}>
        <div className={style.restrictingDiv}>
          {/* div holding content */}
          <div className={style.content}>
            <div>
              <Image src={guaranteeFlower} alt="guaranteeFlower" className={style.guaranteeFlower} />
            </div>
            <div>
              <h2 className={style.headingRight}>
                Our 100% Risk-Free Guarantee
              </h2>
              <p className={style.paragraph}>
                We’re so confident in the transformation our coaching can bring
                that we offer a risk-free, 100% money-back guarantee. If you’re
                not completely satisfied with your experience, you don’t pay.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuaranteeBanner;
