import React from 'react'

import Button from '@/components/home/button'

import style from './landig-header.module.scss'

const LandingPageHeader = () => {
  return (
    <div className={style.main}>
      <div className={style.greySkew} />

      <div className={style.inner}>
        <div className={style.left}>
          <div>
            <h1>LIFE IS HARD. LET'S MAKE IT EASY</h1>
            <p className={style.subheading}>
              Discover your true self with SustainBL’s expert coaching.
            </p>
          </div>
          <div>
            <Button label={'GET STARTED'} className={style.getBtn} />
          </div>
        </div>
        <div className={style.right}>
          <iframe
            src="https://www.youtube.com/embed/xP3kSzB-IE8?si=iW3yHfkVDRpMuhsz"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default LandingPageHeader
