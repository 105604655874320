import React from 'react'
import style from './help-section.module.scss'
const HelpSection = () => {
  return (
    <div className={style.wrapper}>
      <h2 className={style.heading}>
        HOW WE CAN <span style={{ color: '#F2745C' }}>HELP?</span>
      </h2>
      <div className={style.helpContent}>
        <p className={style.topHeading}>
          Starting coaching services is an exciting journey, though it can feel
          overwhelming at first. 
        </p>
        <h3>
          SustainBL is here to support you, every step of the way. Our coaching
          services connect you with expert coaches and courses to align you with
          your strongest, truest self.
        </h3>
        <div className={style.listDiv}>
          <ul>
            <li>
              <span className={style.highlight}>Personalised Coaching:</span>{' '}
              Tailored guidance to address your unique challenges and
              aspirations.
            </li>
            <li>
              <span className={style.highlight}>Emergency Support:</span> Life
              happens. Get the help you need, when you need it.
            </li>
            <li>
              <span className={style.highlight}>Empowerment Techniques:</span>{' '}
              Learn practical tools to build self-confidence and resilience.
            </li>
            <li>
              <span className={style.highlight}>Mindset Shifts:</span> Transform
              negative thinking patterns into positive, empowering beliefs.
            </li>
            <li>
              <span className={style.highlight}>
                Goal Setting and Achievement:
              </span>{' '}
              Create actionable plans to reach your goals, both big and small.
            </li>
            <li>
              <span className={style.highlight}>
                Accountability and Support:
              </span>{' '}
              Stay motivated and focused with regular check-ins and
              encouragement.
            </li>
            <li>
              <span className={style.highlight}>Experienced Coaches:</span> Our
              certified coaches have a proven track record of helping clients
              achieve extraordinary results.
            </li>
            <li>
              <span className={style.highlight}>Compassionate Approach:</span>{' '}
              We believe in creating a safe and supportive space for you to
              grow.
            </li>
            <li>
              <span className={style.highlight}>Holistic Well-being:</span> We
              address all aspects of your life, including physical, mental, and
              emotional health.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HelpSection
