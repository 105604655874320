'use client';

import LandingPageHeader from '@/components/home/header';
import ClientSays from '@/components/home/client-says';
import LifeCoaching from '@/components/home/life-coaching';
import SustainBl from '@/components/home/sustain-bl';
import Services from '@/components/home/services';
import ProgramJourney from '@/components/home/program-journey';
import PricingTable from '@/components/home/pricing-table';
import PricingTableAlt from '@/components/home/pricing-table-alt';
import Course from '@/components/home/course';
import GoldBanner from '@/components/home/gold-banner';
import AboutCoach from '@/components/home/about-coach';
import HelpSection from '@/components/home/help';
import GreyBanner from '@/components/home/grey-banner';
import NextStep from '@/components/home/next-step';
import GuaranteeBanner from '@/components/home/guarantee-banner';
import FaqSection from '@/components/home/faq-section';
import Newsletter from '@/components/home/newsletter';
import SocialSection from '@/components/home/social-section';

export default function Home() {
  return (
    <>
      <div className="hidden lg:block">
        <LandingPageHeader />
      </div>
      <ClientSays />
      <LifeCoaching />
      <Services />
      <ProgramJourney />
      <PricingTable />
      <SustainBl />
      <Course />
      <GoldBanner />
      <AboutCoach />
      <HelpSection />
      <GreyBanner />
      <NextStep />
      <PricingTableAlt />
      <GuaranteeBanner />
      <FaqSection />
      <Newsletter />
      <SocialSection />
    </>
  );
}
