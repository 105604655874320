import React from 'react';
import { FC } from 'react';
import { servicesTheme } from '@/config/services-theme';

// Change this to export the type
export type ServiceType = 
  | 'relationshipCoaching'
  | 'parentingCoaching'
  | 'familyCoaching'
  | 'mensCoaching'
  | 'womensCoaching'
  | 'sustainmeComplete'
  | 'careerCoaching'
  | 'individualCoaching'
  | 'energyHealing'
  | 'reikiMastery'
  | 'default'

interface ServiceIconProps {
  service: ServiceType;
  className?: string;
  width?: number;
  height?: number;
}

const ServiceIcon: FC<ServiceIconProps> = ({ 
  service, 
  className = '', 
  width = 120, 
  height = 120 
}) => {
  const theme = servicesTheme[service] || servicesTheme.default;

  const icons: Record<ServiceType, JSX.Element> = {
    relationshipCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M45 50c-5.523 0-10 4.477-10 10s4.477 10 10 10c3.45 0 6.5-1.744 8.29-4.395L60 75l6.71-9.395C68.5 68.256 71.55 70 75 70c5.523 0 10-4.477 10-10s-4.477-10-10-10c-3.45 0-6.5 1.744-8.29 4.395L60 45l-6.71 9.395C51.5 51.744 48.45 50 45 50z"
              stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M40 80c10-10 30-10 40 0"
              stroke={theme.accent} strokeWidth="3" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    parentingCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M40 45c0-11 40-11 40 0s-40 11-40 0z"
              stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M50 75c0-8 20-8 20 0s-20 8-20 0z"
              stroke={theme.accent} strokeWidth="3" fill="none"/>
        <path d="M60 55v15"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    familyCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <circle cx="60" cy="45" r="12" stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <circle cx="45" cy="75" r="12" stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <circle cx="75" cy="75" r="12" stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M60 57L45 63M60 57L75 63"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <path d="M45 87L75 87"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    mensCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M60 85V35M60 35l-15 15M60 35l15 15"
              stroke={theme.secondary} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        <path d="M35 85h50"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    womensCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M40 80c20-30 20-30 40 0"
              stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M60 85c-15 0-25-15-25-25s10-25 25-25s25 15 25 25"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    careerCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M40 85h15v-15h15v-15h15v-15"
              stroke={theme.secondary} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        <path d="M85 40l-5-5l5-5"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    individualCoaching: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <circle cx="60" cy="60" r="20" stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M60 35v-10M85 60h10M60 85v10M35 60h-10M75 45l7-7M75 75l7 7M45 75l-7 7M45 45l-7-7"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    energyHealing: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M30 50c20-10 40 10 60 0M30 60c20-10 40 10 60 0M30 70c20-10 40 10 60 0"
              stroke={theme.secondary} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <path d="M60 40v40M40 60h40"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    reikiMastery: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M60 35v50M45 60h30"
              stroke={theme.secondary} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <path d="M60 85c-15 0-25-15-25-25s10-25 25-25s25 15 25 25s-10 25-25 25"
              stroke={theme.accent} strokeWidth="3" strokeLinecap="round" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    sustainmeComplete: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M60 90c-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 13.255 0 24.537 8.589 28.477 20.507"
              stroke={theme.secondary} strokeWidth="3" strokeLinecap="round" fill="none"
              transform="rotate(45, 60, 60)"/>
        <path d="M45 55c0 0 5-12 15-12s15 12 15 12"
              stroke={theme.accent} strokeWidth="3" fill="none"/>
        <path d="M75 65c0 0-5 12-15 12s-15-12-15-12"
              stroke={theme.accent} strokeWidth="3" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    ),
    default: (
      <svg width={width} height={height} viewBox="0 0 120 120" fill="none" className={className}>
        <circle cx="60" cy="60" r="58" stroke={theme.primary} strokeWidth="3" fill="none"/>
        <path d="M45 50c-5.523 0-10 4.477-10 10s4.477 10 10 10c3.45 0 6.5-1.744 8.29-4.395L60 75l6.71-9.395C68.5 68.256 71.55 70 75 70c5.523 0 10-4.477 10-10s-4.477-10-10-10c-3.45 0-6.5 1.744-8.29 4.395L60 45l-6.71 9.395C51.5 51.744 48.45 50 45 50z"
              stroke={theme.secondary} strokeWidth="3" fill="none"/>
        <path d="M40 80c10-10 30-10 40 0"
              stroke={theme.accent} strokeWidth="3" fill="none"/>
        <circle cx="60" cy="60" r="4" fill={theme.primary}/>
      </svg>
    )
  };

  return icons[service] || icons.default;
};

export default ServiceIcon; 