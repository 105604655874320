import React from "react";

import goldUnderline from "@/assets/icons/ggold-underline.svg";
import sustainLarge from "@/assets/icons/sustain-large.svg";
import style from "./sustain.module.scss";
import Image from "next/image";
const SustainBl = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.textSection}>
        <h1 className={style.heading}>UNFULFILLED? STRUGGLING? ALONE?</h1>
        <p className={style.subheading}>
          WE'LL CHANGE THAT
          <Image
            src={goldUnderline}
            alt="goldUnderline"
            className={style.goldLine}
          />
        </p>
      </div>
      <h1 className={style.heading}>INTRODUCING</h1>
      <Image src={sustainLarge} alt="sustain-large"  className={style.sustainLogo}/>
    </div>
  );
};

export default SustainBl;
