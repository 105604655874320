'use client'
import React, { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import appLogo from '@/assets/icons/app-logo.svg'
import orangeLogo from '@/assets/icons/orange-logo-small.svg'
import Button from '../../button'
import style from './navbar.module.scss'
import { navigationMenuTriggerStyle } from "@/components/ui/navigation-menu"
import { ModeToggle } from '@/components/global/mode-toggle'
import { cn } from "@/lib/utils"

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "@/components/ui/navigation-menu"

const services = [
  {
    title: "Spiritual Growth",
    theme: "emerald",
    description: "White Lotus - Purity & Spiritual Awakening",
    items: [
      {
        title: "Reiki Mastery",
        href: "/services/reiki-mastery",
        description: "Learn to channel universal life force energy for healing and spiritual growth."
      },
      {
        title: "Energy Healing",
        href: "/services/energy-healing",
        description: "Experience holistic healing through powerful energy work and balance restoration."
      }
    ]
  },
  {
    title: "Love & Relationships",
    theme: "rose",
    description: "Pink Lotus - Love & Compassion",
    items: [
      {
        title: "Relationship Coaching",
        href: "/services/relationship-coaching",
        description: "Build stronger connections and create lasting harmony in relationships."
      },
      {
        title: "Family Coaching",
        href: "/services/family-coaching",
        description: "Create a thriving family environment with expert guidance and support."
      },
      {
        title: "Parenting Coaching",
        href: "/services/parenting-coaching",
        description: "Learn effective strategies for positive parenting and family relationships."
      }
    ]
  },
  {
    title: "Personal Wisdom",
    theme: "blue",
    description: "Blue Lotus - Knowledge & Growth",
    items: [
      {
        title: "Individual Coaching",
        href: "/services/individual-coaching",
        description: "One-on-one coaching to unlock your potential and achieve personal goals."
      },
      {
        title: "Career Coaching",
        href: "/services/career-coaching",
        description: "Strategic guidance to advance your career and achieve professional success."
      }
    ]
  },
  {
    title: "Achievement & Empowerment",
    theme: "amber",
    description: "Gold Lotus - Success & Empowerment",
    items: [
      {
        title: "Women's Coaching",
        href: "/services/womens-coaching",
        description: "Specialized coaching to empower women in all aspects of life."
      },
      {
        title: "Men's Coaching",
        href: "/services/mens-coaching",
        description: "Tailored coaching to help men build authentic strength and purpose."
      }
    ]
  }
]

const ListItem = React.forwardRef(({ className, title, children, theme = "gray", ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            `group block select-none rounded-md p-4 no-underline outline-none transition-all
            hover:bg-${theme}-50 hover:shadow-md hover:-translate-y-0.5
            focus:bg-${theme}-50 focus:shadow-md
            border border-transparent hover:border-${theme}-100`,
            className
          )}
          {...props}
        >
          <div className={`text-base font-medium text-${theme}-900 mb-2 group-hover:text-${theme}-700`}>
            {title}
          </div>
          <p className={`text-sm text-${theme}-600 leading-relaxed group-hover:text-${theme}-500`}>
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const pathname = usePathname()
  const isServicesPage = pathname?.startsWith('/services')

  const toggleMenu = () => {
    setOpenMenu((prevState) => !prevState)
  }

  return (
    <div className={style.navWrapper}>
      <div className={style.navInner}>
        <div>
          <Link href={'/'}>
            <Image src={appLogo} alt="app-logo" className={style.appLogo} />
          </Link>
        </div>  
        <div className={style.navLinks}>
          <NavigationMenu className="overflow-visible relative">
            <NavigationMenuList>
              <NavigationMenuItem>
                <Link href="/programs" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    PROGRAMS
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    PARTNERS
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/blog" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    BLOG
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <Link href="/about-us" legacyBehavior passHref>
                  <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                    ABOUT
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
        </div>
        <div className={style.navBtns}>
          <Link href="/contact">
            <Button className={style.contactBtn} label={'CONTACT US'} />
          </Link>
          <Link href="/login">
            <Button className={style.loginBtn} label={'LOGIN'} />
          </Link>
          <div className="ml-2">
            <ModeToggle />
          </div>
        </div>
      </div>
      <div className={style.navMobile}>
        <Image src={orangeLogo} alt="mobile-logo" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          onClick={toggleMenu}
        >
          <path
            d="M3 12H21M3 6H21M3 18H21"
            stroke="#254956"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {openMenu && (
        <div className={style.navMobileMenu}>
          <div className={style.navLinksMobile}>
            <Link href="/services">
              <span className={style.navLabels}>ALL SERVICES</span>
            </Link>
            <Link href="/programs">
              <span className={style.navLabels}>PROGRAMS</span>
            </Link>
            <Link href="/">
              <span className={style.navLabels}>PARTNERS</span>
            </Link>
            <Link href="/blog">
              <span className={style.navLabels}>BLOG</span>
            </Link>
            <Link href="/about-us">
              <span className={style.navLabels}>ABOUT</span>
            </Link>
          </div>
          <div className={style.navBtnsMobile}>
            <Link href="/contact">
              <Button className={style.contactBtn} label={'CONTACT US'} />
            </Link>
            <Link href="/login">
              <Button className={style.loginBtn} label={'LOGIN'} />
            </Link>
            <div className="mt-4 flex justify-center">
              <ModeToggle />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavBar
