import { FC } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

// Theme configuration
const theme = {
  colors: {
    background: '#004D40',
    cardBase: '#004D40',
    cardHighlighted: '#00695C',
    cardBorder: {
      base: 'white/10',
      highlighted: 'white/20'
    },
    text: {
      primary: 'white',
      secondary: 'white/90',
      tertiary: 'white/80'
    },
    button: {
      base: {
        background: '#00897B',
        hover: '#00796B',
        text: 'white'
      },
      highlighted: {
        background: 'white',
        hover: 'white/90',
        text: '#004D40'
      }
    },
    badge: {
      background: 'white',
      text: '#004D40'
    }
  },
  spacing: {
    sectionPadding: 'py-20',
    cardPadding: 'p-8',
    gap: 'gap-8',
    marginBottom: 'mb-12'
  },
  typography: {
    title: 'text-4xl font-bold',
    subtitle: 'text-lg',
    planName: 'text-2xl font-bold',
    price: 'text-4xl font-bold',
    description: 'text-base',
    features: 'text-base',
    badge: 'text-sm font-semibold',
    button: 'font-semibold'
  },
  layout: {
    maxWidth: 'max-w-7xl',
    container: 'container',
    grid: 'lg:grid-cols-3'  // Using lg breakpoint for alt version
  },
  animation: {
    hoverScale: 1.02,
    duration: 0.2
  }
};

const pricingPlans = [
  {
    name: "Essential Growth",
    price: "997",
    description: "Perfect for individuals starting their transformation journey",
    features: [
      "6 Weekly Group Coaching Sessions",
      "Basic Practice Materials",
      "Community Forum Access",
      "Email Support",
      "Progress Tracking Tools"
    ],
    highlighted: false,
    ctaText: "Get Started"
  },
  {
    name: "Complete Transformation",
    price: "1997",
    description: "Our most popular program for comprehensive growth",
    features: [
      "Everything in Essential Growth",
      "3 Private 1:1 Coaching Sessions",
      "Advanced Practice Materials",
      "Priority Support Access",
      "Personalized Growth Plan",
      "Lifetime Access to Materials"
    ],
    highlighted: true,
    ctaText: "Transform Now"
  },
  {
    name: "VIP Experience",
    price: "3997",
    description: "Premium support for accelerated transformation",
    features: [
      "Everything in Complete Transformation",
      "8 Private 1:1 Coaching Sessions",
      "VIP Community Access",
      "24/7 Priority Support",
      "Custom Program Adjustments",
      "Monthly Progress Reviews",
      "Extended Support (3 months)"
    ],
    highlighted: false,
    ctaText: "Join VIP"
  }
];

const PricingTableAlt: FC = () => {
  const getCardClasses = (highlighted: boolean) => `
    relative ${theme.spacing.cardPadding} rounded-2xl border-2
    ${highlighted 
      ? `bg-[${theme.colors.cardHighlighted}] border-${theme.colors.cardBorder.highlighted}` 
      : `bg-[${theme.colors.cardBase}] border-${theme.colors.cardBorder.base}`}
  `;

  const getButtonClasses = (highlighted: boolean) => `
    w-full py-3 px-6 rounded-lg ${theme.typography.button} transition-colors duration-200
    ${highlighted
      ? `bg-[${theme.colors.button.highlighted.background}] text-[${theme.colors.button.highlighted.text}] hover:bg-${theme.colors.button.highlighted.hover}`
      : `bg-[${theme.colors.button.base.background}] text-${theme.colors.button.base.text} hover:bg-[${theme.colors.button.base.hover}]`}
  `;

  return (
    <section className={`${theme.spacing.sectionPadding} bg-[${theme.colors.background}] text-${theme.colors.text.primary}`}>
      <div className={`${theme.layout.container} mx-auto px-4`}>
        <h2 className={`${theme.typography.title} text-center mb-4`}>Ready to Transform?</h2>
        <p className={`text-${theme.colors.text.secondary} text-center ${theme.spacing.marginBottom} max-w-2xl mx-auto`}>
          Select your preferred program and begin your journey to personal growth and transformation.
        </p>

        <div className={`grid ${theme.layout.grid} ${theme.spacing.gap} ${theme.layout.maxWidth} mx-auto`}>
          {pricingPlans.map((plan) => (
            <motion.div
              key={plan.name}
              className={getCardClasses(plan.highlighted)}
              whileHover={{ scale: theme.animation.hoverScale }}
              transition={{ duration: theme.animation.duration }}
            >
              {plan.highlighted && (
                <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className={`bg-${theme.colors.badge.background} text-[${theme.colors.badge.text}] ${theme.typography.badge} px-4 py-1 rounded-full`}>
                    Most Popular
                  </span>
                </div>
              )}
              
              <h3 className={`${theme.typography.planName} mb-2 text-${theme.colors.text.primary}`}>{plan.name}</h3>
              <div className="mb-4">
                <span className={`${theme.typography.price} text-${theme.colors.text.primary}`}>${plan.price}</span>
                <span className={`text-${theme.colors.text.tertiary}`}> / program</span>
              </div>
              <p className={`mb-6 text-${theme.colors.text.tertiary}`}>{plan.description}</p>
              
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <Check className={`w-5 h-5 mt-0.5 text-${plan.highlighted ? theme.colors.text.primary : theme.colors.text.tertiary}`} />
                    <span className={`${theme.typography.features} text-${theme.colors.text.secondary}`}>{feature}</span>
                  </li>
                ))}
              </ul>
              
              <button className={getButtonClasses(plan.highlighted)}>
                {plan.ctaText}
              </button>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className={`text-${theme.colors.text.secondary}`}>
            Questions about our programs?{' '}
            <a href="#contact" className={`text-${theme.colors.text.primary} font-semibold hover:text-${theme.colors.text.tertiary} underline`}>
              Book a free consultation call
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricingTableAlt; 