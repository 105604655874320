import(/* webpackMode: "eager" */ "/codebuild/output/src3904619244/src/sustainbl-web/components/home/layout/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/components/home/layout/navbar/index.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3904619244/src/sustainbl-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3904619244/src/sustainbl-web/node_modules/next/font/google/target.css?{\"path\":\"app/(home)/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3904619244/src/sustainbl-web/components/home/layout/footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/public/assets/icons/bin-footer.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/public/assets/icons/linkdin-footer.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/public/assets/icons/sustain-large.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3904619244/src/sustainbl-web/public/assets/icons/twitter-footer.svg");
