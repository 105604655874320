import React from "react";

import fb from "@/assets/icons/facebook.svg";
import yt from "@/assets/icons/youtube.svg";
import style from "./social.module.scss";
import Image from "next/image";
const SocialSection = () => {
  return (
    <div className={style.wrapper}>
      <Image src={yt} alt="youtube" className={style.youtubeIcon} />
      <Image src={fb} alt="facebook" className={style.facebookIcon} />
    </div>
  );
};

export default SocialSection;
