import { FC } from 'react';
import ServiceIcon from '../services/ServiceIcon';
import { servicesTheme } from '@/config/services-theme';
import { motion } from 'framer-motion';
import { ServiceType } from '@/components/services/ServiceIcon';

interface Service {
  id: ServiceType;
  title: string;
  description: string;
}

const services: Service[] = [
  {
    id: 'sustainmeComplete',
    title: 'SustainMe Complete',
    description: 'A comprehensive six-week journey to transform your mindset and develop emotional resilience.'
  },
  {
    id: 'relationshipCoaching',
    title: 'Relationship Coaching',
    description: 'Build stronger, healthier relationships through expert guidance and proven strategies.'
  },
  {
    id: 'parentingCoaching',
    title: 'Parenting Coaching',
    description: 'Develop effective parenting skills and create a nurturing environment for your children.'
  },
  {
    id: 'familyCoaching',
    title: 'Family Coaching',
    description: 'Strengthen family bonds and create harmony in your household through targeted coaching.'
  },
  {
    id: 'mensCoaching',
    title: "Men's Coaching",
    description: 'Specialized coaching for men focusing on personal growth, leadership, and emotional intelligence.'
  },
  {
    id: 'womensCoaching',
    title: "Women's Coaching",
    description: 'Empowering women through personalized coaching focused on growth and self-discovery.'
  },
  {
    id: 'careerCoaching',
    title: 'Career Coaching',
    description: 'Navigate your career path with confidence and achieve your professional goals.'
  },
  {
    id: 'individualCoaching',
    title: 'Individual Coaching',
    description: 'Personalized one-on-one coaching to help you reach your full potential.'
  },
  {
    id: 'energyHealing',
    title: 'Energy Healing',
    description: 'Restore balance and promote healing through energy-based therapeutic practices.'
  },
  {
    id: 'reikiMastery',
    title: 'Reiki Mastery',
    description: 'Learn and master the art of Reiki healing for personal and professional practice.'
  }
];

const ServiceCard: FC<{
  service: Service;
}> = ({ service }) => {
  const theme = servicesTheme[service.id];
  
  return (
    <motion.div
      className="relative group"
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.2 }}
    >
      <div 
        className={`w-full aspect-square rounded-2xl flex items-center justify-center transition-colors duration-300 ${theme.background}`}
        style={{ borderColor: theme.primary }}
      >
        <ServiceIcon service={service.id} width={80} height={80} />
      </div>
      
      {/* Hover Description */}
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="bg-white/95 p-4 rounded-2xl shadow-lg w-full h-full flex flex-col items-center justify-center text-center">
          <h3 className="font-semibold mb-2" style={{ color: theme.primary }}>{service.title}</h3>
          <p className="text-sm text-gray-600">{service.description}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Services: FC = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-4">Our Services</h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          Discover our comprehensive range of coaching and healing services designed to support your personal growth and transformation.
        </p>
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 max-w-6xl mx-auto">
          {services.map((service) => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services; 