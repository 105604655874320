import React from 'react'
import style from './news-letter.module.scss'
import client2 from '@/assets/icons/client2.svg'
import Image from 'next/image'
import Button from '../button'
const Newsletter = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.leftDiv}>
        <div className={style.heading}>
          <p>SUBSCRIBE TO OUR</p>
          <p style={{ color: '#F2745C', marginTop: '-25px' }}>NEWSLETTER</p>
          <div className={style.borderLine} />
        </div>
        <p className={style.desc}>
          Stay Updated on the Latest in Special Education Support and Resources!
        </p>
        <div className={style.inputDiv}>
          <input className={style.inputClass} placeholder="Email" required />
          <Button label={'SUBSCRIBE'} className={style.btnClass} />
        </div>
      </div>
    </div>
  )
}

export default Newsletter
