import React from "react";

import style from "./button.module.scss";
const Button = ({ label, className }) => {
  return (
    <button className={`${className} ${style.btnClass}`}>
      <span className={style.btnLabel}>{label}</span>
    </button>
  );
};

export default Button;
