import React from 'react'

import conversation from '@/assets/icons/orange-conversation.svg'
import plan from '@/assets/icons/orange-plan.svg'
import emergency from '@/assets/icons/orange-emergency.svg'

import style from './life-coaching.module.scss'
import Image from 'next/image'
const LifeCoaching = () => {
  return (
    <div className={style.wrapper}>
      <h1 className={style.heading}>
        THREE
        <span style={{ color: '#F2745C' }}> PROBLEMS </span>
        IN LIFE COACHING
      </h1>
      <div className={style.orangeCardsDiv}>
        {oranges.map((e, index) => (
          <div className={style.orangeCard} key={e.heading + e.subHeading}>
            <Image src={e?.icon} alt={`orange-icon-${index}`} />
            <p className={style.orangeHeading}>{e.heading}</p>
            <p className={style.orangeSubHeading}>{e.subHeading}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LifeCoaching

const oranges = [
  {
    icon: conversation,
    heading: 'Aimless Conversations',
    subHeading: 'Many feel lost without a clear path.',
  },
  {
    icon: plan,
    heading: 'No Plan',
    subHeading: 'Unproductive talk that lacks direction and focus.',
  },
  {
    icon: emergency,
    heading: 'No Emergency Support',
    subHeading: 'You need someone who’s there in critical moments.',
  },
]
