import React, { useState } from 'react'
import style from './faq.module.scss'

import plus from '../../../public/assets/icons/plus.svg'
import minus from '../../../public/assets/icons/minus.svg'
import Image from 'next/image'

const FaqSection = () => {
  const [openIndex, setOpenIndex] = useState(null)

  const toggle = (index) => {
    setOpenIndex(openIndex === index ? null : index)
  }
  return (
    <div className={style.wrapperFaq}>
      <h2 className={style.heading}>Frequently Asked Questions</h2>
      <div className={style.barsSection}>
        {items.map((item, index) => (
          <div
            key={index}
            className={style.faqRow}
            style={{ cursor: 'pointer' }}
            onClick={() => toggle(index)}
          >
            <div className={style.question}>
              <p>{item?.title}</p>
              <Image src={openIndex === index ? minus : plus} alt="plus" />
            </div>

            <div
              className={`${style.answer} ${
                openIndex === index && style.answerOpen
              }`}
            >
              {item?.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqSection

let items = [
  {
    title: 'What types of coaching services does SustainBL offer?',
    description:
      'SustainBL offers a comprehensive range of coaching services across four main areas: Spiritual Growth (including Reiki Mastery and Energy Healing), Love & Relationships (including Family and Parenting Coaching), Personal Wisdom (Individual and Career Coaching), and Achievement & Empowerment (specialized coaching for both women and men).',
  },
  {
    title: 'What makes SustainBL\'s coaching approach unique?',
    description:
      'Our approach combines traditional coaching methods with holistic practices, emphasizing both personal and spiritual growth. We use the symbolism of the lotus flower to represent different aspects of development: White Lotus for spiritual purity, Pink Lotus for love and compassion, Blue Lotus for wisdom, and Gold Lotus for achievement and empowerment.',
  },
  {
    title: 'How does the coaching process work?',
    description:
      'We begin with a personalized assessment to understand your needs and goals. Whether you\'re seeking spiritual guidance, relationship support, career advancement, or personal growth, we create a tailored coaching plan. Sessions can be conducted one-on-one or in specialized group settings, depending on your preferences and needs.',
  },
  {
    title: 'What results can I expect from coaching?',
    description:
      'While results vary by individual, our clients typically report improved clarity in decision-making, stronger relationships, enhanced career satisfaction, and deeper spiritual connection. We focus on creating sustainable, long-term positive changes rather than quick fixes.',
  },
  {
    title: 'Do you offer both personal and professional development?',
    description:
      'Yes, we offer both personal and professional development coaching. Our career coaching helps with professional advancement, while our personal development programs cover everything from spiritual growth to relationship building. Many clients find that progress in one area naturally enhances growth in others.',
  },
  {
    title: 'How do I get started with SustainBL coaching?',
    description:
      'Getting started is simple: Browse our services to find the right program for you, schedule a consultation through our website, and we\'ll match you with the perfect coach for your needs. We offer flexible scheduling and various coaching formats to accommodate your lifestyle.',
  },
]
