import React from "react";
import style from "./course.module.scss";

import Button from "@/components/home/button";

import course from "@/assets/icons/course-large.svg";
import Image from "next/image";
const Course = () => {
  return (
    <div className={style.wrapper}>
      <h2 className={style.heading}>RISE ABOVE YOUR CIRCUMSTANCE</h2>
      <Image src={course} alt="course-image" className={style.courseImage} />
      <div className={style.bottomText}>
        <h2 className={style.heading}>
          THE ULTIMATE STEP-BYSTEP ONLINE VIDEO COURSE
        </h2>
        <h2 className={style.heading}>
          JUST IN{" "}
          <span style={{ color: "#f2745c", fontWeight: "420" }}>$297</span>
        </h2>
      </div>
      <Button label={"GET INSTANCE ACCESS"} className={style.btn} />
    </div>
  );
};

export default Course;
