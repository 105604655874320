interface ServiceTheme {
  primary: string;
  secondary: string;
  accent: string;
  background?: string;
}

interface ServicesThemeConfig {
  [key: string]: ServiceTheme;
}

export const servicesTheme: ServicesThemeConfig = {
  default: {
    primary: '#059669',
    secondary: '#10B981',
    accent: '#34D399',
    background: '#F9FAFB'
  },
  relationshipCoaching: {
    primary: '#BE185D',
    secondary: '#DB2777',
    accent: '#F472B6',
    background: '#FDF2F8'
  },
  parentingCoaching: {
    primary: '#7C3AED',
    secondary: '#8B5CF6',
    accent: '#A78BFA',
    background: '#F5F3FF'
  },
  familyCoaching: {
    primary: '#BE185D',
    secondary: '#DB2777',
    accent: '#F472B6',
    background: '#FDF2F8'
  },
  mensCoaching: {
    primary: '#1D4ED8',
    secondary: '#2563EB',
    accent: '#3B82F6',
    background: '#EFF6FF'
  },
  womensCoaching: {
    primary: '#C026D3',
    secondary: '#D946EF',
    accent: '#E879F9',
    background: '#FAF5FF'
  },
  careerCoaching: {
    primary: '#0891B2',
    secondary: '#06B6D4',
    accent: '#22D3EE',
    background: '#ECFEFF'
  },
  individualCoaching: {
    primary: '#4338CA',
    secondary: '#4F46E5',
    accent: '#6366F1',
    background: '#EEF2FF'
  },
  energyHealing: {
    primary: '#9333EA',
    secondary: '#A855F7',
    accent: '#C084FC',
    background: '#F5F3FF'
  },
  reikiMastery: {
    primary: '#7E22CE',
    secondary: '#9333EA',
    accent: '#A855F7',
    background: '#F5F3FF'
  },
  sustainmeComplete: {
    primary: '#059669',
    secondary: '#10B981',
    accent: '#34D399',
    background: '#ECFDF5'
  }
}; 