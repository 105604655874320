import { FC } from 'react';
import { motion } from 'framer-motion';

const ProgramJourney: FC = () => {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-4">Your Transformation Journey</h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          Experience a structured approach to personal growth through our comprehensive coaching programs.
        </p>

        {/* Journey Steps */}
        <div className="max-w-4xl mx-auto mb-20">
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div 
              className="p-6 bg-emerald-50 rounded-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <div className="w-12 h-12 rounded-full bg-emerald-600 text-white flex items-center justify-center font-bold mb-4">1</div>
              <h3 className="text-xl font-semibold mb-4">Foundation Building</h3>
              <p className="text-gray-600">Begin with core mindfulness practices and perspective-shifting techniques to establish a strong foundation for growth.</p>
            </motion.div>
            
            <motion.div 
              className="p-6 bg-emerald-50 rounded-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <div className="w-12 h-12 rounded-full bg-emerald-600 text-white flex items-center justify-center font-bold mb-4">2</div>
              <h3 className="text-xl font-semibold mb-4">Skill Development</h3>
              <p className="text-gray-600">Master practical tools for emotional regulation, stress management, and conscious decision-making.</p>
            </motion.div>
            
            <motion.div 
              className="p-6 bg-emerald-50 rounded-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <div className="w-12 h-12 rounded-full bg-emerald-600 text-white flex items-center justify-center font-bold mb-4">3</div>
              <h3 className="text-xl font-semibold mb-4">Integration & Mastery</h3>
              <p className="text-gray-600">Apply learned techniques in daily life and develop sustainable practices for long-term growth.</p>
            </motion.div>
          </div>
        </div>

        {/* Program Components */}
        <h3 className="text-3xl font-bold text-center mb-12">Program Components</h3>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          <motion.div 
            className="p-6 border border-emerald-200 rounded-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <h4 className="text-xl font-semibold mb-4">1:1 Coaching Sessions</h4>
            <p className="text-gray-600">Personalized guidance and support from experienced coaches tailored to your unique needs.</p>
          </motion.div>
          
          <motion.div 
            className="p-6 border border-emerald-200 rounded-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <h4 className="text-xl font-semibold mb-4">Practice Materials</h4>
            <p className="text-gray-600">Comprehensive workbooks, guided meditations, and practical exercises for daily implementation.</p>
          </motion.div>
          
          <motion.div 
            className="p-6 border border-emerald-200 rounded-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <h4 className="text-xl font-semibold mb-4">Community Support</h4>
            <p className="text-gray-600">Access to a supportive community of like-minded individuals on similar growth journeys.</p>
          </motion.div>
          
          <motion.div 
            className="p-6 border border-emerald-200 rounded-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <h4 className="text-xl font-semibold mb-4">Growth Tracking</h4>
            <p className="text-gray-600">Tools and frameworks to monitor your progress and celebrate transformational milestones.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ProgramJourney; 